import { PageElement } from "easy-email-pro-core"

import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./SeasonalSavingsSpotlight.png"
import contentJson from "./seasonalSavingsSpotlight.json"

const template: SystemEmailTemplate = {
  imgSrc,
  id: "seasonalSavingsSpotlight",
  name: "New arrivals template for e-commerce",
  description:
    "Drive engagement and sales by showcasing your latest discounts and new arrivals, giving customers quick access to the season's best deals and fashion trends.",
  contentJson: contentJson as PageElement,
}

export default template
