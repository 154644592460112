import { lazy, Suspense, useEffect, useState } from "react"
import classNames from "classnames"
import { PageElement } from "easy-email-pro-core"
import { isEmpty, pick } from "ramda"
import { Controller, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"
import Button from "components/UI/elements/Button/Button"
import ConfirmModal from "components/UI/components/ConfirmModal"
import EmailContentHeader from "components/EmailContentHeader/EmailContentHeader"
import { contentToHtmlString } from "components/EmailEditor/utils"
import HtmlPreview from "components/HtmlPreview/HtmlPreview"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import PaperHeader from "components/UI/elements/PaperHeader"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { showToast } from "app/toast"
import { required } from "helpers/validators.helper"
import { useFetchAllEmailTemplates } from "resources/email/emailTemplate/emailTemplateQueries"
import {
  EmailTemplate,
  EmailTemplatePayload,
} from "resources/email/emailTemplate/emailTemplateTypes"
import { MODAL, TOAST } from "sharedConstants"
import { EmailContentType, HTMLView, NullableEmailContentType } from "types/util"
import { parseJsonContent } from "utilities/channel"
import styles from "./EmailTemplateForm.module.scss"
const AceEditor = lazy(() => import("components/AceEditor/AceEditor"))
const EmailEditor = lazy(() => import("components/EmailEditor/EmailEditor"))
const EmailEditorProvider = lazy(() => import("components/EmailEditor/EmailEditorProvider"))

type FormValues = EmailTemplatePayload

type EmailTemplateFormProps = {
  onSubmit: (data: FormValues, onSuccess?: () => void) => void
  defaultValues?: EmailTemplatePayload & { id?: EmailTemplate["id"] }
  isCreate?: boolean
  onCopy?: (values: EmailTemplatePayload) => void
  onDelete?: () => void
  onUseTemplate?: (values: EmailTemplatePayload) => void
  isLoading?: boolean
}

export default function EmailTemplateForm({
  defaultValues,
  isCreate,
  onDelete,
  onCopy,
  onUseTemplate,
  onSubmit,
  isLoading,
}: EmailTemplateFormProps) {
  const [contentType, setContentType] = useState<NullableEmailContentType>(null)
  const [switchContentTypeModalOpen, setSwitchContentTypeModalOpen] = useState(false)
  const [switched, setSwitched] = useState(false)

  const [htmlView, setHtmlView] = useState<HTMLView>(isCreate ? "code" : "desktop")
  const [emailEditorContent, setEmailEditorContent] = useState<PageElement | undefined>()
  const [emailEditorDirty, setEmailEditorDirty] = useState(false)

  useEffect(() => {
    if (isCreate && !defaultValues?.content_json) {
      return
    }

    const JSONContent = defaultValues?.content_json ?? null
    const parsedJSONContent = JSONContent && parseJsonContent(JSONContent)
    if (parsedJSONContent && isEmpty(parsedJSONContent)) setContentType("html")
    else setContentType("json")
  }, [defaultValues?.content_json, isCreate])

  const {
    control,
    handleSubmit,
    register,
    reset,
    unregister,
    watch,
    formState: { dirtyFields, errors, isSubmitting, isDirty: isFormDirty, isSubmitted },
  } = useForm<FormValues>({
    // Typescript doesn't typecheck when we pass an object with extra properties so we have to strip those
    defaultValues: defaultValues
      ? pick(["name", "content_json", "content_html"], defaultValues)
      : {
          content_html: "",
          content_json: "",
          name: "",
        },
  })

  const html = watch("content_html")

  const templatesQuery = useFetchAllEmailTemplates()
  const nameIsUnique = async (name: string) =>
    new Promise<string | undefined>(resolve => {
      while (templatesQuery.isLoading) {}

      const nameCollision = (existing: EmailTemplate) =>
        existing.name === name && existing.id !== defaultValues?.id

      if (templatesQuery.data?.some(nameCollision)) {
        resolve("This name is already used")
      }

      resolve(undefined)
    })

  const openModal = () => setSwitchContentTypeModalOpen(true)
  const closeModal = () => setSwitchContentTypeModalOpen(false)

  const switchContent = (newValue: EmailContentType) => {
    if (newValue === "html") {
      register("content_html", { value: "" })
      setEmailEditorContent(undefined)
      setEmailEditorDirty(false)
    } else {
      unregister("content_html")
      setHtmlView("code")
    }

    setSwitched(true)
    setContentType(newValue)
  }

  const onChangeContentType = (newValue: EmailContentType) => {
    if (dirtyFields.content_html || emailEditorDirty) openModal()
    else switchContent(newValue)
  }

  const onModalConfirm = () => {
    closeModal()
    switchContent(contentType === "html" ? "json" : "html")
  }

  const toSubmitPayload = (formValues: FormValues) => {
    let content_html = formValues.content_html
    let content_json = "{}"

    if (contentType === "json" && emailEditorContent) {
      content_html = contentToHtmlString(emailEditorContent)
      content_json = JSON.stringify(emailEditorContent)
    }

    return {
      ...formValues,
      content_html,
      content_json,
    }
  }

  const onSubmitForm = (formValues: FormValues) => {
    if (contentType === null) {
      showToast("Please select HTML or Visual builder.", TOAST.TYPE.ERROR)
      return
    }

    onSubmit(formValues, () => {
      reset(formValues)
      setSwitched(false)
      if (contentType === "json") setEmailEditorDirty(false)
    })
  }

  const content = (editor: JSX.Element) => (
    <Paper className={styles.content}>
      <EmailContentHeader
        hideContentSwitch={!isCreate}
        contentType={contentType}
        htmlView={htmlView}
        setHtmlView={setHtmlView}
        onChangeContentType={onChangeContentType}
      />
      {editor}
    </Paper>
  )

  const isCopied = Boolean(isCreate && defaultValues)
  const isDirty = isFormDirty || emailEditorDirty || (!isCreate && switched)

  return (
    <>
      <Prompt
        when={(isDirty || isCopied) && !(isSubmitting || isSubmitted) && !isLoading}
        message="Changes you made will not be saved."
      />
      <form onSubmit={handleSubmit(formValues => onSubmitForm(toSubmitPayload(formValues)))}>
        <PaperHeader size="small" className={styles.header}>
          <div>
            <TextInput
              label="Name"
              labelPosition="left"
              {...register("name", { validate: { required, nameIsUnique } })}
              error={errors.name?.message}
              placeholder="Name"
              maxLength={100}
            />
          </div>
          <div className={styles.buttons}>
            {!isCreate && (
              <>
                {onCopy && (
                  <Button
                    color="grey"
                    variant="outlined"
                    onClick={handleSubmit(formValues => onCopy(toSubmitPayload(formValues)))}
                  >
                    Copy
                  </Button>
                )}
                {onUseTemplate && (
                  <Button
                    color="grey"
                    variant="outlined"
                    onClick={handleSubmit(formValues => onUseTemplate(toSubmitPayload(formValues)))}
                  >
                    Use template
                  </Button>
                )}
                <Button color="red" variant="outlined" onClick={onDelete}>
                  Delete
                </Button>
              </>
            )}
            <Button loading={isSubmitting} type="submit">
              Save
            </Button>
          </div>
        </PaperHeader>

        {contentType === null && (
          <>
            <Paper className={styles.content}>
              <h3>Content</h3>
              <div className={styles.placeholder}>
                <div className={styles.columnFlexBox}>
                  <span className={styles.text}>Select type of e-mail:</span>
                  <div className={styles.rowFlexBox}>
                    <Button icon="code" onClick={() => setContentType("html")}>
                      HTML
                    </Button>
                    <span className={styles.text}>or</span>
                    <Button icon={["far", "objects-column"]} onClick={() => setContentType("json")}>
                      Visual Editor
                    </Button>
                  </div>
                </div>
              </div>
            </Paper>
          </>
        )}
        {contentType === "html" &&
          content(
            <>
              {htmlView === "code" ? (
                <Suspense fallback={<LoadingIndicator />}>
                  <p className={styles.label}>HTML *</p>
                  <Controller
                    control={control}
                    name="content_html"
                    rules={{ validate: { required } }}
                    render={({ field: { value, onChange } }) => (
                      <AceEditor
                        wrapEnabled
                        height="700px"
                        width="1160px"
                        mode="html"
                        name="html"
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{ tabSize: 2, showPrintMargin: false }}
                        value={value ?? undefined}
                        theme="tomorrow"
                        onChange={(...args) => onChange(...args)}
                        className={classNames(styles.aceEditor, {
                          [styles.error]: errors.content_html,
                        })}
                        errorMessage={errors.content_html?.message}
                      />
                    )}
                  />
                </Suspense>
              ) : (
                <HtmlPreview html={html} isMobile={htmlView === "mobile"} />
              )}
            </>,
          )}
      </form>
      {contentType === "json" &&
        content(
          <Suspense fallback={<LoadingIndicator />}>
            <EmailEditorProvider initialValues={defaultValues?.content_json ?? ""}>
              <EmailEditor setContent={setEmailEditorContent} setDirty={setEmailEditorDirty} />
            </EmailEditorProvider>
          </Suspense>,
        )}

      <ConfirmModal
        open={switchContentTypeModalOpen}
        type={MODAL.TYPE.CANCEL}
        handleClose={closeModal}
        handleConfirm={onModalConfirm}
        title="Are you sure?"
        text="The content will be erased."
      />
    </>
  )
}
