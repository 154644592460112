import { PageElement } from "easy-email-pro-core"

import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./BackToSchoolEcommerce.png"
import contentJson from "./backToSchoolEcommerce.json"

const template: SystemEmailTemplate = {
  imgSrc,
  id: "backToSchoolEcommerce",
  name: "Seasonal campaign with a bold burst of color",
  description:
    "Show your brand's character and grab customers' attention with a bright color accent and smart use of white space.",
  contentJson: contentJson as PageElement,
}

export default template
