import { PageElement } from "easy-email-pro-core"

import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./RealEstateInsider.png"
import contentJson from "./realEstateInsider.json"

const template: SystemEmailTemplate = {
  imgSrc,
  id: "realEstateInsider",
  name: "Balanced product promotion template",
  description:
    "Showcase your content or product offering with this classic template fitting for every industry.",
  contentJson: contentJson as PageElement,
}

export default template
