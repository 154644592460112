import { lazy, Suspense, useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useCreateEmail } from "resources/email/emailQueries"
import { CreateEmailPayload, Email } from "resources/email/emailTypes"
import { getRoutePath } from "routes"
import Page from "components/UI/Page/Page"
import styles from "./EmailCreate.module.scss"
import ChatGPT from "components/ChatGPT/ChatGPT"
import { useFetchDefaultSenderAddress } from "resources/channel/emailSenders/emailSendersQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { EmailTemplatePayload } from "resources/email/emailTemplate/emailTemplateTypes"
const EmailForm = lazy(() => import("../components/EmailForm/EmailForm"))

export default function EmailCreate() {
  const history = useHistory()
  const mutation = useCreateEmail()
  const { email, emailTemplate } =
    useLocation<{ email?: Email; emailTemplate?: EmailTemplatePayload } | undefined>().state ?? {}

  const create = (data: CreateEmailPayload, onSettled: () => void) =>
    mutation.mutateAsync(
      { data },
      {
        onSettled,
        onSuccess: ({ email: { id } }) => {
          history.push(getRoutePath("channels.emails.detail", { id }))
        },
      },
    )

  const { data: defaultSenderAddress, isLoading } = useFetchDefaultSenderAddress()

  // email editor initial values has to be memoized otherwise it will reset them after submit fails
  const initialValues = useMemo(() => {
    return (
      email ?? {
        from_email: { email_address: defaultSenderAddress },
        reply_to_email: defaultSenderAddress,
        content_html: emailTemplate?.content_html,
        content_json: emailTemplate?.content_json,
      }
    )
  }, [email, defaultSenderAddress, emailTemplate])

  return (
    <Page
      title="Create email"
      contentClassName={styles.container}
      backRouteFallback={
        emailTemplate ? getRoutePath("channels.emails.templates") : getRoutePath("channels.emails")
      }
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <Suspense fallback={<LoadingIndicator />}>
          <EmailForm
            isCreate
            isLoading={mutation.isLoading}
            onSubmit={create}
            initialValues={initialValues}
            isFromCopy={Boolean(email)}
          />
        </Suspense>
      )}
    </Page>
  )
}
