import { getRoutePath } from "routes"
import { useHistory, useLocation } from "react-router-dom"
import EmailTemplateForm from "../components/EmailTemplateForm/EmailTemplateForm"
import Page from "components/UI/Page/Page"
import { EmailTemplatePayload } from "resources/email/emailTemplate/emailTemplateTypes"
import { useCreateEmailTemplate } from "resources/email/emailTemplate/emailTemplateQueries"

export default function EmailTemplateCreate() {
  const history = useHistory()
  const emailTemplate = useLocation<{ emailTemplate: EmailTemplatePayload } | undefined>().state
    ?.emailTemplate

  const createMutation = useCreateEmailTemplate()

  const onSubmit = (data: EmailTemplatePayload, onSuccess?: () => void) =>
    createMutation.mutate(
      { data },
      {
        onSuccess({ email_template: { id } }) {
          onSuccess?.()
          history.push(getRoutePath("channels.emails.templates.detail", { id }))
        },
      },
    )

  return (
    <Page
      title="Create email template"
      backRouteFallback={getRoutePath("channels.emails.templates")}
    >
      <EmailTemplateForm
        isCreate
        isLoading={createMutation.isLoading}
        defaultValues={emailTemplate}
        onSubmit={onSubmit}
      />
    </Page>
  )
}
