import { Dispatch, SetStateAction } from "react"
import Button from "components/UI/elements/Button/Button"
import SelectField from "components/UI/elements/SelectField"
import { EmailContentType, HTMLView, NullableEmailContentType } from "types/util"
import { contentTypeOptions } from "utilities/channel"
import styles from "./EmailContentHeader.module.scss"

type EmailContentHeaderProps = {
  contentType: NullableEmailContentType
  htmlView: HTMLView
  setHtmlView: Dispatch<SetStateAction<HTMLView>>
  hideContentSwitch?: boolean
  onChangeContentType?: (contentType: EmailContentType) => void
}

export default function EmailContentHeader({
  contentType,
  htmlView,
  onChangeContentType,
  setHtmlView,
  hideContentSwitch = false,
}: EmailContentHeaderProps) {
  if (contentType === null) return null

  return (
    <div className={styles.header}>
      {contentType === "html" ? (
        <div className={styles.htmlPreview}>
          <h3>Content</h3>
          <Button
            color={htmlView === "code" ? "primary" : "grey"}
            icon={["far", "code"]}
            variant="text"
            onClick={() => setHtmlView("code")}
          >
            Code editor
          </Button>
          <Button
            color={htmlView === "desktop" ? "primary" : "grey"}
            icon="desktop"
            variant="text"
            onClick={() => setHtmlView("desktop")}
          >
            Preview desktop
          </Button>
          <Button
            color={htmlView === "mobile" ? "primary" : "grey"}
            icon="mobile"
            variant="text"
            onClick={() => setHtmlView("mobile")}
          >
            Preview mobile
          </Button>
        </div>
      ) : (
        <h3>Content</h3>
      )}
      {!hideContentSwitch && (
        <SelectField
          isSimpleValue
          input={{
            value: contentType,
            onChange: (newValue: EmailContentType) => {
              if (newValue === contentType) return
              onChangeContentType?.(newValue)
            },
          }}
          options={contentTypeOptions}
          size="tag"
        />
      )}
    </div>
  )
}
