import classNames from "classnames"
import InfoMessage from "components/UI/components/InfoMessage/InfoMessage"
import styles from "./HtmlPreview.module.scss"

type HtmlPreviewProps = {
  html: string | null
  isMobile?: boolean
  showLabel?: boolean
  showInfoMessage?: boolean
  previewMessage?: string
}

export default function HtmlPreview({
  html,
  previewMessage,
  isMobile = false,
  showLabel = true,
  showInfoMessage = true,
}: HtmlPreviewProps) {
  return (
    <div className={styles.wrapper}>
      {showLabel && <span className={styles.label}>Preview</span>}
      {showInfoMessage && (
        <InfoMessage>
          {previewMessage ?? (
            <>
              The preview may not fully reflect how your email will appear across all email clients.
              For accurate results, we recommend using the 'Send Test' functionality to ensure your
              email appears as intended.
            </>
          )}
        </InfoMessage>
      )}
      <div
        className={classNames(styles.preview, {
          [styles.centered]: !html || isMobile,
        })}
      >
        {!html && <span className={styles.message}>Fill the html code to see the preview</span>}
        {html && !isMobile && <iframe title="desktop preview" srcDoc={html} />}
        {html && isMobile && (
          <div className={styles.mobile}>
            <iframe title="mobile preview" srcDoc={html} />
          </div>
        )}
      </div>
    </div>
  )
}
