import { PageElement } from "easy-email-pro-core"

import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./WeekendCulinaryDelights.png"
import contentJson from "./weekendCulinaryDelights.json"

const template: SystemEmailTemplate = {
  imgSrc,
  id: "weekendCulinaryDelights",
  name: "Clean template design for dark theme lovers",
  description:
    "Pair high-quality images with short descriptions and call-to-actions to make your newsletters easily scannable.",
  contentJson: contentJson as PageElement,
}

export default template
