import { PageElement } from "easy-email-pro-core"

import { SystemEmailTemplate } from "../../SystemEmailTemplateItem/SystemEmailTemplateItem"
import imgSrc from "./CulinaryExplorer.png"
import contentJson from "./culinaryExplorer.json"

const template: SystemEmailTemplate = {
  imgSrc,
  id: "culinaryExplorer",
  name: "General newsletter template",
  description:
    "Use this graphic-rich template to share updates, new products, and important news with your subscribers.",
  contentJson: contentJson as PageElement,
}

export default template
