import { easyEmailToEasyEmailPro } from "easy-email-pro-core"
import {
  BlockManager,
  Element,
  ElementType,
  PageElement,
  EditorCore,
  BasicElement,
  TextNode,
  t,
} from "easy-email-pro-core"
import { IconFont } from "easy-email-pro-theme"
// @ts-ignore
import mjmlToHtml from "mjml-browser"
import { has, isEmpty, last } from "ramda"

import { api } from "api"
import { ME_URL_PLACEHOLDER } from "sharedConstants"
import { generateRandomString } from "utilities/random"
import { EmailEditorProps } from "easy-email-pro-editor"
import {
  IBlockData,
  IPage,
} from "easy-email-pro-core/lib/typings/utils/easyEmailToEasyEmailPro/type"

type Child = Element | TextNode

export type ThemeConfigProps = Omit<EmailEditorProps, "children">

export type ChildCondition = (child: Child) => boolean

export const isElement = (element: Child): element is Element => has("type", element)

export const isContentEmpty = (content: PageElement) => content.children.length === 0

export const contentPlaceholder = BlockManager.getBlockByType(ElementType.PAGE).create({
  data: {
    breakpoint: "480px",
    globalAttributes: {
      "font-family": "'Helvetica Neue', sans-serif",
    },
  },
  type: "page",
  children: [
    {
      type: "standard-section",
      data: {},
      attributes: {},
      children: [
        {
          type: "standard-column",
          data: {},
          attributes: {},
          children: [
            {
              type: "placeholder",
              data: {},
              attributes: {},
              children: [
                {
                  text: "",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  attributes: {
    "background-color": "#f5f5f5",
    "content-background-color": "#ffffff",
  },
})

const hasElementOnlyGivenChild = (element: Element, givenType: string) =>
  element.children.length === 1 &&
  isElement(element.children[0]) &&
  element.children[0].type.endsWith(givenType)

const hasEmptyTextNode = (element: Element) =>
  element.children.length === 1 &&
  !isElement(element.children[0]) &&
  isEmpty(element.children[0].text)

export const isContentPlaceholder = ({ content }: { content: PageElement }) => {
  if (!hasElementOnlyGivenChild(content, "standard-section")) return false
  const section = content.children[0] as Element

  if (!hasElementOnlyGivenChild(section, "standard-column")) return false
  const column = section.children[0] as Element

  if (!hasElementOnlyGivenChild(column, "standard-paragraph")) return false
  const textElement = column.children[0] as Element

  return hasEmptyTextNode(textElement)
}

const isOpenSourceContent = (content: IPage) => {
  let isOpenSource = false

  const traverseBlockData = (blockData: IBlockData) => {
    if (
      [
        "advanced_button",
        "advanced_column",
        "advanced_image",
        "advanced_navbar",
        "advanced_section",
        "advanced_social",
        "advanced_spacer",
        "advanced_text",
      ].includes(blockData.type)
    ) {
      isOpenSource = true
      return
    } else if (blockData.children?.length > 0) blockData.children?.map(b => traverseBlockData(b))
  }

  traverseBlockData(content)
  return isOpenSource
}

const proContentToMjml = (content: PageElement) =>
  EditorCore.toMJML({
    element: content,
    pageElement: content,
    mode: "production",
  })

export const openSourceContentToPro = (content: any): PageElement =>
  isOpenSourceContent(content) ? easyEmailToEasyEmailPro(content) : (content as PageElement)

export const contentToHtmlString = (content: PageElement): string =>
  mjmlToHtml(proContentToMjml(content)).html

export const onUploadImage = async (blob: Blob) => {
  const fileExtension = last(blob.type.split("/"))
  const fileName = generateRandomString(24)
  const file = new File([blob], `${fileName}.${fileExtension}`)

  const formData = new FormData()
  formData.append("file", file)

  const response = await api.image.emails.post(formData)
  return new Promise<string>((resolve, reject) => {
    if (response.image_url) resolve(response.image_url)
    else reject()
  })
}

export const hasLinkCssClass = (basicElement: BasicElement, className: string) =>
  has("css-class", basicElement.attributes) &&
  typeof basicElement.attributes["css-class"] === "string" &&
  basicElement.attributes["css-class"].includes(className)

const replaceChild = (child: Child, f: (child: Child) => Child): Child =>
  isElement(child) && child.children.length > 0 && child.children.every(c => isElement(c))
    ? { ...child, children: child.children.map(c => replaceChild(c, f)) }
    : f(child)

const HASHTAG = "#"

const fillTextNodeHref = (textNode: TextNode): TextNode => {
  if (textNode.link)
    return { ...textNode, link: { ...textNode.link, href: textNode.link.href ?? HASHTAG } }

  return { ...textNode, link: { href: HASHTAG } }
}

const fillHrefConditionally = (child: Child, condition: ChildCondition): Child => {
  if (condition(child) && isElement(child))
    return {
      ...child,
      children: child.children.map(c => (!isElement(c) ? fillTextNodeHref(c) : c)),
    }

  return child
}

export const replaceHrefInContentConditionally = (
  content: PageElement,
  condition: ChildCondition,
): PageElement => ({
  ...content,
  children: content.children.map(child =>
    replaceChild(child, e => fillHrefConditionally(e, condition)),
  ),
})

const replaceImageSrcAttribute = (child: Child, meUrl: string): Child =>
  isElement(child) &&
  child.type === "standard-image" &&
  has("src", child.attributes) &&
  typeof child.attributes.src === "string" &&
  child.attributes.src.includes(ME_URL_PLACEHOLDER)
    ? {
        ...child,
        attributes: {
          ...child.attributes,
          src: child.attributes.src.replace(ME_URL_PLACEHOLDER, meUrl),
        },
      }
    : child

export const replaceImageSrcInContent = (content: PageElement, meUrl: string): PageElement => ({
  ...content,
  children: content.children.map(child =>
    replaceChild(child, e => replaceImageSrcAttribute(e, meUrl)),
  ),
})

export const isContentValid = (content: PageElement, condition: ChildCondition): boolean => {
  let isValid = false

  const traverseContent = (element: Child) => {
    if (condition(element)) {
      isValid = true
      return
    } else if (isElement(element) && element.children.length > 0)
      element.children.map(c => traverseContent(c))
  }

  traverseContent(content)
  return isValid
}

export const CATEGORIES: ThemeConfigProps["categories"] = [
  {
    get label() {
      return t("Content")
    },
    active: true,
    displayType: "grid",
    blocks: [
      {
        type: ElementType.STANDARD_PARAGRAPH,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-text" />,
      },
      {
        type: ElementType.STANDARD_IMAGE,
        payload: {},
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-img" />,
      },
      {
        type: ElementType.STANDARD_BUTTON,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-button" />,
      },
      {
        type: ElementType.STANDARD_DIVIDER,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-divider" />,
      },
      {
        type: ElementType.STANDARD_SPACER,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-spacing" />,
      },
      {
        type: ElementType.STANDARD_NAVBAR,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-navbar" />,
      },
      {
        type: ElementType.STANDARD_SOCIAL,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-social" />,
      },
      {
        type: ElementType.STANDARD_HERO,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-hero" />,
        payload: {
          type: "standard-hero",
          data: {},
          attributes: {
            "background-width": "1080px",
            "background-height": "721px",
            "padding-top": "100px",
            "padding-bottom": "50px",
            "background-image-enabled": true,
            "background-url":
              "http://res.cloudinary.com/djnkpbshx/image/upload/v1698677931/easy-email-pro-test/t75ucncjgmm5vwp6r2s4.jpg",
            "background-position": "center center",
            mode: "fluid-height",
          },
          children: [
            {
              type: "standard-h1",
              data: {},
              attributes: {
                color: "#FFFFFF",
              },
              children: [
                {
                  text: "We Serve Healthy & Delicious Foods",
                },
              ],
            },
            {
              type: "standard-paragraph",
              data: {},
              attributes: {
                color: "#FFFFFF",
              },
              children: [
                {
                  text: "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
                },
              ],
            },
            {
              type: "standard-button",
              data: {
                content: "Button",
              },
              attributes: {
                "padding-top": "30px",
                "padding-bottom": "30px",
                "background-color": "#8b2a36",
              },
              children: [
                {
                  text: "Get Your Order Here!",
                },
              ],
            },
          ],
        },
      },
      {
        type: ElementType.STANDARD_COLUMN,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-column" />,
      },
      {
        type: ElementType.WRAPPER,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-wrapper" />,
        payload: {
          type: "standard-wrapper",
          data: {},
          children: [
            {
              type: "standard-section",
              data: {},
              attributes: {},
              children: [
                {
                  type: "standard-column",
                  attributes: {},
                  data: {},
                  children: [
                    {
                      type: "placeholder",
                      data: {},
                      attributes: {},
                      children: [
                        {
                          text: "",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        type: ElementType.STANDARD_SECTION,
        icon: <IconFont className="block-list-grid-item-icon" iconName="icon-section" />,
        payload: {
          type: "standard-section",
          data: {},
          attributes: {},
          children: [
            {
              type: "standard-column",
              attributes: {},
              data: {},
              children: [
                {
                  type: "placeholder",
                  data: {},
                  attributes: {},
                  children: [
                    {
                      text: "",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    get label() {
      return t("Layout")
    },
    active: true,
    displayType: "column",
    blocks: [
      {
        get title() {
          return t("1 column")
        },
        payload: [["100%"]],
      },
      {
        get title() {
          return t("2 column")
        },
        payload: [
          ["50%", "50%"],
          ["33%", "67%"],
          ["67%", "33%"],
          ["25%", "75%"],
          ["75%", "25%"],
        ],
      },
      {
        get title() {
          return t("3 column")
        },
        payload: [
          ["33.33%", "33.33%", "33.33%"],
          ["25%", "50%", "25%"],
          ["25%", "25%", "50%"],
          ["50%", "25%", "25%"],
        ],
      },
      {
        get title() {
          return t("4 column")
        },
        payload: [["25%", "25%", "25%", "25%"]],
      },
    ],
  },
]
